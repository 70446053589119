import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';

const UserText = props => {
  const { text } = props;
  const context = useContext(FunnelContext);
  const {
    extraObjects: { user },
    currency
  } = context;

  const currencySymbol = currency?.symbol || '$';

  const InnerHTML = () => {
    let currentText = text;

    currentText = currentText?.replace(/\[name\]/gm, user?.name ?? '');
    currentText = currentText?.replace(
      /\[declined-reason\]/gm,
      user?.declined_reason ?? ''
    );
    currentText = currentText?.replace(
      /\[total\]/gm,
      user?.total.toFixed(2) ?? '0.00'
    );
    currentText = currentText?.replace(
      /\[currency-symbol\]/gm,
      currencySymbol ?? '$'
    );

    return {
      __html: currentText
    };
  };

  return <div {...props.attributes} dangerouslySetInnerHTML={InnerHTML()} />;
};

export default UserText;
